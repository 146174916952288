<template>
  <div class="box">
    <a-row :gutter="12" class="box-row">
      <a-col span="8" class="box-col">
        <div class="custom-card-box info-box">
          <base-info />
        </div>
      </a-col>
      <a-col span="16" class="box-col">
        <router-view />
        <div class="custom-card-box info-box" v-if="$route.name === 'hall_info'">
          <a-row :gutter="12" class="right-info">
            <a-col span="12" class="right-info-col">
              <router-link :to="`/halls/${id}/death`">
                <div class="right-info-content">
                  <img
                    alt="img"
                    src="/temp/death_info.png"
                  >
                  <h1>逝者信息</h1>
                </div>
              </router-link>
            </a-col>
            <a-col span="12" class="right-info-col">
              <div class="right-info-content" @click="toProduct">
                <img
                  alt="img"
                  src="/temp/sub_package.png"
                >
                <h1>订购产品</h1>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <div class="wechat-sub" v-if="qrCode">
              <div class="wechat-content">
                <div class="wechat-sub-qr">
                  <qrcode-vue
                    v-if="qrCode"
                    :value="qrCode"
                    :size="80"
                    level="H"
                  />
                </div>
                <span>微信扫码订购殡仪物品</span>
              </div>
            </div>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <valid-phone-number
      v-if="isShowValidPhoneNumberModal"
      :visible.sync="isShowValidPhoneNumberModal"
      :service-order-id="data.service_order_id"
      :master-phone="data.master_phone"
      @completed="handleToProduct"
    />
  </div>
</template>

<script>
import BaseInfo from '@/views/halls/show/pc/in_use/BaseInfo'
import { findSubscribeOrderQrCodeUrl } from '@/api/service_order'
import { findHall } from '@/api/hall'

export default {
  name: 'ShowHallInUseInfo',
  components: {
    BaseInfo,
    QrcodeVue: () => import('qrcode.vue'),
    ValidPhoneNumber: () => import('@/views/halls/show/pc/in_use/ValidPhoneNumber')
  },
  data() {
    return {
      qrCode: '',
      isShowValidPhoneNumberModal: false,
      data: {}
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findHall(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          findSubscribeOrderQrCodeUrl(this.data.service_order_id).then((res) => {
            if (res.code === 0) {
              this.qrCode = res.data.qr_code_url
            }
          })
        }
      })
    },

    toProduct() {
      if (this.data.is_can_subscribe) {
        this.isShowValidPhoneNumberModal = true
      } else {
        this.$warning({
          title: '当前订单状态不能增加殡仪服务新增项订单',
          content: ''
        })
      }
    },

    handleToProduct() {
      this.$router.push({ name: 'product_info', id: this.id, query: { service_order_id: this.data.service_order_id }})
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  height: 100%;

  .box-row {
    height: 100%;

    .box-col {
      height: 100%;
      flex: 1;

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;

        .right-info {
          display: flex;
          align-items: flex-start;
          padding-top: 60px;
          align-content: flex-start;

          .right-info-col {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .right-info-content {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 250px;
            height: 300px;
            background-color: #37543e;
            flex-direction: column;

            img {
              width: 200px;
            }

            h1 {
              color: @white;
            }
          }
        }
      }
    }
  }
}

.wechat-sub {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
  flex-direction: column;
  height: 100%;
  align-content: center;

  .wechat-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;

    .wechat-sub-qr {
      margin-bottom: 10px;
      padding: 10px;
      background-color: white;
    }
  }
}
</style>

