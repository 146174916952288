import request from '@/utils/request'

export function findSubscribeOrderQrCodeUrl(id) {
  return request({
    url: `/service_orders/${id}/wechat/subscribe_orders/qr_code_url`,
    method: 'get'
  })
}

// 查询立屏感恩仪式和告别仪式时间
export function findMobileServiceOrderThankfulLeaveTime(id) {
  return request({
    url: `/mobile/service_orders/${id}/thankful_leave_time`,
    method: 'get'
  })
}

// 查询立屏订单负责人
export function findMobileServiceOrderUser(id) {
  return request({
    url: `/mobile/service_orders/${id}/user`,
    method: 'get'
  })
}

// 查询立屏订单注意事项
export function findMobileServiceOrderNotices(id) {
  return request({
    url: `/mobile/service_orders/${id}/notices`,
    method: 'get'
  })
}

// 查询订单已购产品
export function findMobileServiceOrderOtherItems(id) {
  return request({
    url: `/mobile/service_orders/${id}/other_items`,
    method: 'get'
  })
}

