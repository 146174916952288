import request from '@/utils/request'

// 厅房详情
export function findHall(id) {
  return request({
    url: `/halls/${id}`,
    method: 'get'
  })
}

// 立屏厅房节点信息
export function findMobileHallNodes(id) {
  return request({
    url: `/mobile/halls/${id}/nodes`,
    method: 'get'
  })
}
