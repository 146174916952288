<template>
  <div class="left-info">
    <h1 class="custom-word-break-all">{{ data.name }}</h1>
    <img
      v-show="data.images && data.images.length > 0"
      alt="img"
      v-for="(item, index) in data.images"
      :key="index"
      :src="item.url"
    >
    <img
      v-show="!data.images || data.images.length === 0"
      alt="img"
      src="/temp/no_picture.png"
    >
    <span>{{ data.remark }}</span>
  </div>
</template>

<script>
import { findHall } from '@/api/hall'
export default {
  name: 'HallBaseInfo',
  data() {
    return {
      data: {}
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findHall(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.left-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  h1 {
    color: #ffd794ff;
  }

  img {
    margin-bottom: 20px;
    width: 100%;
    min-height: 350px;
  }
}
</style>
